@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap');
$randomNumber:random(5);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root,
main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

}
*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
a{
  text-decoration: none;
  color:inherit;
}

main {
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.canvas {
  order: 2;
  flex: 1;
  height: 100%;
}

.overlay {
  position: relative;
  order: 1;
  flex: 1;
  /* pointer-events: none; */
}

.div {
  position: absolute;
  top:1270px;
  order: 1;
  flex: 1;
  pointer-events: none;
  background: #f0f0f0;
  width: 100%;
}

svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media only screen and (max-aspect-ratio: 8/7) {
  main {
    flex-direction: column;
  }
  .canvas {
    order: 1;
  }
  
}
  .Overh1{
    color: #E8B059;
    position: absolute;
    top:15%;
    left:10%;
    font-size: 86px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* background-image: -webkit-linear-gradient(left, #147B96, #E6D205 25%, #147B96 50%, #E6D205 75%, #147B96);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation: maskedAnimation 4s infinite linear; */
  }
  .Overp{
    color:#8a8aa0;
    position: absolute;
    top:40%;
    left:10%;
    
  }
  .linerCol{
    background: linear-gradient( #E8B059 0%, #6b6969 80%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 90px;
  }
  .module1{
    /* border:#c9c1b3 1px solid; */
    color: #f0f0f0;
    display: flex;
    justify-content: space-around;
    position: relative;
    top:60%;
  }
  .module2{
    /* border:#c9c1b3 1px solid; */
    color: #f0f0f0;
    display: flex;
    justify-content: space-around;
    position: relative;
    top:85%;
  }
  .module1 div {
    /* border:#E8B059 1px solid; */
    margin-left: 60px;
  }
  .module2 div {
    /* border:#E8B059 1px solid; */
    margin-left: 60px;
  }
  .Transaction{
    font-size: 28px;
    font-style: italic;
    margin-left:15px;
    /* background-color: #00c4ff; */
    -webkit-text-fill-color: rgb(160, 153, 153);
    -webkit-text-stroke:1px rgb(247, 244, 244);
  }
  .Frag{
    margin: 15px 20px;
    color: #ccc;
    font-style: italic;
    flex-direction: column;
  }


.h1font{
  max-height: 100vh;
}




//h1::before {
//  content: "";
//  position: absolute;
//  top: 0;
//  width: 200vw;
//  height: 35px;
//  background-color: rgba(32, 32, 32, 0.9);
//  transform: rotate(45deg);
//  animation: scan-light 4s ease-in infinite;
//}

